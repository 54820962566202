import React, { Component } from "react";
import "./Loader.scss";
import {gsap} from "gsap";

export class Loader extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            reverse: true
        };        
        // reference to the animation
        this.tl = null;
    }
    
    componentDidMount() {
        // use the node ref to create the animation
        this.tl = gsap.timeline({ paused: true, defaults: { duration: 1 } });
        this.launchLoading();
    }
    componentWillUnmount() {
        //this.tl = null;
    }
    componentDidUpdate(oldProps) {
        // if not done and was done
        // start animation
        if(oldProps.done && !this.props.done) {
            document.body.classList.add('myloading');
            this.tl.seek(0).play();    
        }
    }
    launchLoading() {
        let _this = this;
        this.tl.set('.loading', { 'display': 'flex' });
        this.tl.add("start");
        this.tl.to(".top, .bottom", .4, {
            y: 0,
            ease: 'power2.inOut'
        });

        this.tl.to('.line', {
            width: '50%',
            opacity: 1,
            duration: 0.5
        }, -0.1);

        this.tl.add('text-start');

        this.tl.to('.top p span', 0.6, {
            marginBottom: '0',
            opacity: 1,
        }, 'text-start');

        this.tl.to('.bottom p span', 0.6, {
            marginTop: '0',
            opacity: 1,
        }, 'text-start');

        this.tl.to('.line', 0.4, {
            opacity: 0,
            onComplete: function () {
                _this.props.gotoPage();
                _this.setState({ reverse: false });
            },
        }, 'text-start');
        this.tl.to('.border-shape', 1.2, {
            width: '150px',
            height: '150px',
            opacity: 1,
            rotation: 225,
            ease: 'power2.inOut'
        }, 'text-start');
        this.tl.add('square-in');
        this.startWaiting();
    }
    startWaiting() {
        let _this = this;
        this.tl.add('chevron-start');
        var chevronDuration = .8;
        this.tl.to('.chevron.top', chevronDuration, {
            top: '-40px',
            ease: 'power2.inOut',
            opacity: 1
        }, 'chevron-start-=.4');

        this.tl.to('.chevron.bottom', chevronDuration, {
            bottom: '-40px',
            ease: 'power2.inOut',
            opacity: 1
        }, 'chevron-start-=.4');

        this.tl.to('.chevron.left', chevronDuration, {
            left: '-40px',
            ease: 'power2.inOut',
            opacity: 1
        }, 'chevron-start-=.4');

        this.tl.to('.chevron.right', chevronDuration, {
            right: '-40px',
            ease: 'power2.inOut',
            opacity: 1
        }, 'chevron-start-=.4');

        this.tl.add('chevron-return');
        this.tl.to('.chevron.top', chevronDuration, {
            top: '-20px',
            ease: 'power2.inOut',
            opacity: 0
        }, 'chevron-return');

        this.tl.to('.chevron.bottom', chevronDuration, {
            bottom: '-20px',
            ease: 'power2.inOut',
            opacity: 0
        }, 'chevron-return');

        this.tl.to('.chevron.left', chevronDuration, {
            left: '-20px',
            ease: 'power2.inOut',
            opacity: 0
        }, 'chevron-return');

        this.tl.to('.chevron.right', chevronDuration, {
            right: '-20px',
            ease: 'power2.inOut',
            opacity: 0
        }, 'chevron-return');
        this.tl.to('.border-shape', 0.8, {
            rotation: '+=90',
            ease: 'power2.inOut',
            onComplete: function () {
                if (_this.props.done) {
                    _this.endLoading();
                } else {
                    _this.startWaiting();
                }
                
            },
        });
    }
    endLoading() {
        this.tl.to('.top p span', 0.6, {
            marginBottom: '-40px',
            opacity: 0,
        }, 'text-back');

        this.tl.to('.bottom p span', 0.6, {
            marginTop: '-40px',
            opacity: 0,
        }, 'text-back');
        // after loaded
        this.tl.to('.border-shape', 1.2, {
            width: '10px',
            height: '10px',
            opacity: 0,
            rotation: '-=270',
            ease: 'power2.inOut',
            onComplete: function () {
                
            }
        }, 'text-back');

        this.tl.add('curtain-end');
        this.tl.to(".top", 0.4, {
            y: '-100%',
            ease: 'power2.inOut'
        }, 'curtain-end');
        this.tl.to(".bottom", 0.4, {
            y: '100%',
            ease: 'power2.inOut',
            onComplete: function () {
                document.body.classList.remove('myloading');
            }
        }, 'curtain-end');
        this.tl.set('.loading', { 'display': 'none' });
        
    }
    render() {        
        return (
            <div className="loading" >
                <div className="top curtain">
                    <p>
                        <span>NOW</span>
                    </p>
                    <div className="line"></div>
                </div>
                <div className="bottom curtain">
                    <p>
                        <span>LOADING</span>
                    </p>
                    <div className="line"></div>
                </div>
                <div className="border-shape-wrapper">
                    <div className="chevron top"></div>
                    <div className="chevron right"></div>
                    <div className="chevron bottom"></div>
                    <div className="chevron left"></div>
                    <div className="border-shape"></div>
                </div>
            </div>
        );
    }
}

export default Loader;
