import React, {Component } from 'react';
import NavigationManger from '../../Utils/NavigationManager';
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import '../../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import '../../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import './Tests.scss';

export default class About extends Component {
	constructor(props) {
		super(props);
		this.state = {
			content: ''
		};
		this.controller = new ScrollMagic.Controller();
		this.scene = undefined;
		this.tl = null;

	}
	setStartPage() {
		this.props.setStartPage('/tests');
	}
	componentDidMount() {
		this.setStartPage();
		setTimeout(() => {
			this.setState({
				content:
					<React.Fragment>
						<div className="content kud">
							<div className="parallax-window" data-parallax="scroll" data-image-src="/assets/site/images/pri_50993737.jpg">
								<section className="high pt300">
									<div className="wrapper">
										<div className="container">
											<h1>This is introduction text<br /> for the partnership page that can<br /> extend three or more lines</h1>
											<div className="col-md-6 mt-sm-0 mt-4 mb-5">
												<div className="bg--white-50 p-3">
													<p>Client Security leverages the Group’s long-standing expertise in securing digital content and fighting piracy for some of the world’s largest television operators and content providers.</p>
													<p>Client Security leverages the Group’s long-standing expertise in securing digital content and fighting piracy for some of the world’s largest television operators and content providers.</p>
													<p>Client Security leverages the Group’s long-standing expertise in securing digital content and fighting piracy for some of the world’s largest television operators and content providers.</p>
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
						</div>
						<section className="bg-animation--squaretest h100">
							<div id="trigger2"></div>
							<div className="bg-animation-stage security">
								<div className="squaretest"></div>
							</div>
						</section>
					</React.Fragment>

			});
			this.launchScrollMagic();
			this.props.setIsReady(true);
		}, Math.random() * 2001);
	}

	componentWillUnmount() {
		this.tl.kill();
		this.controller.destroy();
		this.controller = null;
	}

	launchScrollMagic() {
		this.tl = gsap.timeline();
		const _this = this;
		this.scene = new ScrollMagic.Scene({triggerElement: "#trigger2", duration: 500, offset: 0})
			.on("start", function (e) {
				if (e.scrollDirection === 'FORWARD') {
					if (_this.tl['_dur'] === 0) {
						_this.tl.to('.squaretest', {x: '+=600px', rotation: '+=90'});
					}
				}
			})
			.setTween(this.tl)
			// .setPin("#square1")
			.addIndicators({name: "indicator"})
			.addTo(this.controller);
	}

	render() {
		return (<React.Fragment>
				<NavigationManger currentPage={this.props.currentPage} {...this.props} /> {this.state.content}
			</React.Fragment>
		);
	}
}
