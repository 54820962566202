import React, { Component } from "react";
import './Header.scss';
export class Header extends Component {

    render() {
        return (
            <header className="header">
                <div className="container">
                    <nav>
                        <ul>
                            <li>
                                {/* <Link to="/" >Home</Link> */}
                                <a href="/" onClick={e => this.props.preSetNextPage(e, '/')}>Home</a>
                            </li>
                            <li>
                                {/* <Link to="/about" >About</Link> */}
                                <a href="/about" onClick={e => this.props.preSetNextPage(e, '/about')}>About</a>
                            </li>
                            <li>
                                <a href="/tests" onClick={e => this.props.preSetNextPage(e, '/tests')}>Tests</a>
                            </li>
                            
                            
                        </ul>
                    </nav>
                </div>
            </header>
        );
    }
}

export default Header;
