import React, { Component } from 'react';
import NavigationManger from '../../Utils/NavigationManager';
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import '../../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import '../../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import './About.scss';
export default class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: ''
        };
        this.controller = new ScrollMagic.Controller();
        this.scene = undefined;
        this.tl = null;
    }
    setStartPage() {
    	this.props.setStartPage('/about');
	}
    componentDidMount() {
    	this.setStartPage();
        setTimeout(() => {
            this.setState({
                content:
                    <React.Fragment>
                    <div className="content kud">
                        <div className="parallax-window" data-parallax="scroll" data-image-src="/assets/site/images/pri_50993737.jpg">
                            <section className="high pt300">
                                <div className="wrapper">
                                    <div className="container">
                                        <h1>Since it's intro copy for the partnership page that can extend three or more lines</h1>
                                        <div className="col-md-6 mt-sm-0 mt-4 mb-5">
                                            <div className="bg--white-50 p-3">
                                                <p>Client Security leverages the Group’s long-standing expertise in securing digital content and fighting piracy for some of the world’s largest television operators and content providers.</p>
                                                <p>Client Security leverages the Group’s long-standing expertise in securing digital content and fighting piracy for some of the world’s largest television operators and content providers.</p>
                                                <p>Client Security leverages the Group’s long-standing expertise in securing digital content and fighting piracy for some of the world’s largest television operators and content providers.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                    <section className="bg-animation--square h100">
                        <div className="trigger2"></div>
                        <div className="bg-animation-stage security">
                            <div className="square s1"></div>
                            <div className="square s2"></div>
                            <div className="square s3"></div>
                            <div className="square s4"></div>
                            <div className="square s5"></div>
                            <div className="square s6"></div>
                            <div className="square s7"></div>
                            <div className="square-txt">
                                <h2>Securing Content Fighting piracy B to B and B to C Proofing mobile Comms</h2>
                            </div>
                        </div>
                    </section>
                    </React.Fragment>

            });
            this.launchScrollMagic();
            this.props.setIsReady(true);
        }, Math.random() * 2001);
    }
    componentWillUnmount() {
        this.tl.kill();
        this.controller.destroy();
        this.controller = null;
        // this.scene.destroy();

        // this.scene = null;
    }
    launchScrollMagic() {
        this.tl = gsap.timeline();
        //this.tl.to("#s2, #s3, #s4, #s5, #s6, #s7", 1, { className: '+=firstStep' });
        const _this = this;
        this.scene = new ScrollMagic.Scene({ triggerElement: ".trigger2", duration: 1000, offset:-78 })
            .on("start", function (e) {
                if (e.scrollDirection === 'FORWARD') {
					if (_this.tl['_dur'] === 0) {
                        // scroll DOWN                    
                        _this.tl.add('start');
                        _this.tl.to('.s2', 1, { y: '76px' }, 'start');
                        _this.tl.to('.s3', 1, { y: '120px' }, 'start');
                        _this.tl.to('.s4', 1, { y: '156px' }, 'start');
                        _this.tl.to('.s5', 1, { y: '195px' }, 'start');
                        _this.tl.to('.s6', 1, { y: '223px' }, 'start');
                        _this.tl.to('.s7', 1, { y: '252px' }, 'start');
                    }
                }                
            })
            .setTween(this.tl)
            // .addIndicators({ name: "indicator" })
            .addTo(this.controller);
    }

    render() {
        return (<React.Fragment>
            <NavigationManger currentPage={this.props.currentPage} {...this.props} />
            {this.state.content}
        </React.Fragment>
        );
    }
}
