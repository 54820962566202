import React, { Component } from 'react';
import NavigationManger from '../../Utils/NavigationManager';
import { gsap } from "gsap";
import ScrollMagic from "scrollmagic";
import '../../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';
import '../../../../node_modules/scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators';
import './Home.scss';
export class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: ''
        };
        this.controller = new ScrollMagic.Controller();
        this.scene = undefined;
        this.tl = null;
    }
	setStartPage() {
		this.props.setStartPage('/');
	}
    componentDidMount() {
		this.setStartPage();
        setTimeout(() => {
            this.setState({
                content:
                    <div className="content kud">
                        <section className="parterships-intro h100">
                            <div className="trigger"></div>
                            <div className="wrapper">
                                <div className="container">
                                    <h1>This is introduction text<br /> for the partnership page that can<br /> extend three or more lines</h1>
                                    <div className="row mt200">
                                        <div className="col-md-6">
                                            <div className="bg--white p-3">
                                                <p>With more than 150 security experts, Client Security is a trusted security services provider delivering end-to-end consulting, technology, managed services and threat intelligence to help organizations build and run successful security programs. The global reach and cyber solution focus of Client Security are reinforced by key international partnerships.</p>
                                                <p>With more than 150 security experts, Client Security is a trusted security services provider delivering end-to-end consulting, technology, managed services and threat intelligence to help organizations build and run successful security programs. The global reach and cyber solution focus of Client Security are reinforced by key international partnerships.</p>
                                                <p>With more than 150 security experts, Client Security is a trusted security services provider delivering end-to-end consulting, technology, managed services and threat intelligence to help organizations build and run successful security programs. The global reach and cyber solution focus of Client Security are reinforced by key international partnerships.</p>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-sm-0 mt-4">
                                            <div className="bg--white-50 p-3">
                                                <p>Client Security leverages the Group’s long-standing expertise in securing digital content and fighting piracy for some of the world’s largest television operators and content providers.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-animation-stage partnerships fixed">
                                    <div className="gradient blue"></div>
                                    <div className="bg-animation-wrapper">
                                        <div className="circle c1"></div>
                                        <div className="circle c2"></div>
                                        <div className="circle c3"></div>
                                        <div className="circle c4"></div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
            });
            this.launchScrollMagic();
            this.props.setIsReady(true);
        }, Math.random() * 1001);
    }

    componentWillUnmount() {
        this.tl.kill();
        this.controller.destroy();
        this.controller = null;
        // this.scene.destroy();

        // this.scene = null;
    }
    launchScrollMagic() {
		this.tl = gsap.timeline();
        //this.tl.to(".c1, .c2, .c3, .c4", 1, { className: "+=firstStep" });
        //this.tl.to(".c1, .c2, .c3, .c4", 1, { className: "+=secondStep" });
        //this.tl.to(".gradient", 1, { opacity: 0, delay: -0.6 });
        //this.tl.to(".c1", 1, { className: "+=firstStep" });
        //        this.tl.fromTo(".c1", 1, { top: '296px' }, { top: '80' });

        const _this = this;
        new ScrollMagic.Scene({ triggerElement: ".trigger", duration: 1000, offset: 340 })
            .on("start", function (e) {
                if (e.scrollDirection === 'FORWARD') {
                    if (_this.tl['_dur'] === 0) {
                        _this.tl.add('start');
                        _this.tl.to('.c1', 1, { x: '188px', y: '0', opacity: 1 }, 'start');
                        _this.tl.to('.c2', 1, { x: '230px', y: '42px', opacity: 1 }, 'start');
                        _this.tl.to('.c3', 1, { x: '265px', y: '77px', opacity: 1 }, 'start');
                        _this.tl.to('.c4', 1, { x: '321px', y: '133px', opacity: 1 }, 'start');
                        _this.tl.add('fade');
                        _this.tl.to('.c1', 1, { opacity: 0, y: '-275px' }, 'fade');
                        _this.tl.to('.c2', 1, { opacity: 0, y: '-233px' }, 'fade');
                        _this.tl.to('.c3', 1, { opacity: 0, y: '-198px' }, 'fade');
                        _this.tl.to('.c4', 1, { opacity: 0, y: '-143px' }, 'fade');
                        _this.tl.to(".gradient", 1, { opacity: 0, delay: -.6 });

                    }
                } 
            })
            .setTween(this.tl)
            // .addIndicators({name: "indicator"})
            .addTo(this.controller);
    }
    render() {
        return (<React.Fragment>
            <NavigationManger currentPage={this.props.currentPage} {...this.props} />
            {this.state.content}
        </React.Fragment>
        )
    }
}

export default Home
