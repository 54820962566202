import React, { Component } from "react";
import { Router, Route, Switch} from "react-router-dom";
import Header from "./components/layout/Header/Header";
import Loader from "./components/layout/Loader/Loader";
import About from "./components/pages/About/About";
import Home from "./components/pages/Home/Home";
import Tests from "./components/pages/Tests/Tests";
import "./App.scss";
import history from './History';
import ScrollToTop from './components/Utils/ScrollToTop';
class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
            done: true,
            currentPage: undefined,
            nextPage: undefined
        };
        this.setIsReady = this.setIsReady.bind(this);
        this.preSetNextPage = this.preSetNextPage.bind(this);
        this.gotoPage = this.gotoPage.bind(this);
        this.setStartPage = this.setStartPage.bind(this);
    }


    // called on click on nav link
    preSetNextPage(e, page) {
        // console.log('new page: '+ page);
        // console.log('old page: '+ this.props.currentPage);
        this.setIsReady(false);
        e.preventDefault();
        if (page !== this.props.currentPage) {
            this.setState({
                nextPage: page,
                done: false
            });
        }        
    }
    // when loaded page is ready, inform loader
    setIsReady(bol) {
        this.setState({
            done: bol
        });
    }
    // called by Loader when animation is ready, updates nextPage
    // then nextPage is propagated to NavigationManger which update history to new page
    gotoPage() {
        const page = this.state.nextPage;
        this.setState({
            currentPage: page
        });
    }

    // used only for first page loaded, to add it to history
	setStartPage(oldPage) {
    	if (this.state.currentPage === undefined) {
    		this.setState({
				currentPage: oldPage
			});
		}
	}

    render() {
        return (
            <Router history={history}>
                <ScrollToTop />
                    <div className="App">
                        <Loader done={this.state.done} gotoPage={this.gotoPage}/>
                        <Header preSetNextPage={this.preSetNextPage}/>
                        <main>
                        <Switch>
                            <Route exact path="/" render={(props) => <Home {...props} setIsReady={this.setIsReady} setStartPage={this.setStartPage} currentPage={this.state.currentPage} />} />
                            <Route path="/about" render={(props) => <About {...props} setIsReady={this.setIsReady} setStartPage={this.setStartPage} currentPage={this.state.currentPage} />} />
                            <Route path="/tests" render={(props) => <Tests {...props} setIsReady={this.setIsReady} setStartPage={this.setStartPage} currentPage={this.state.currentPage} />} />
                        </Switch>
                        </main>
                    </div>
                
            </Router>
        );
    }
}

export default App;
