import React, { Component } from 'react';

export default class NavigationManager extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currentPage: undefined
        };
    }
    componentDidMount() {
        this.setState({
            currentPage: this.props.currentPage
        });
    }
    componentDidUpdate(oldProps) {
        if (oldProps.currentPage !== this.props.currentPage) {
            this.props.history.push(this.props.currentPage);
        }
    }
    render() {
        return (
            <React.Fragment></React.Fragment>
        );
    }
}
